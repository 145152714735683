import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Col, Row } from 'react-bootstrap';
import { BrazilianFormatData } from '../../../components/BrazilianFormatData';
import AirlineOption from '../../components/AirlineOption';

const PackageSelectHotels = (props) => {
    const [package_, setPackage] = useState({});
    const [eventData, setEventData] = useState([]);
    const [eventBannerStyle, setEventBannerStyle] = useState({
        backgroundImage: 'url()',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    });

    const { id } = useParams();

    useEffect(() => {
        try {
            
            axios
                .get(`${process.env.REACT_APP_API_URL}/frontend/packages/${id}`)
                .then((response) => {
                    let data = response.data.data;
                    
                    setPackage(data);

                    console.log(data);

                    axios.get(`${process.env.REACT_APP_API_URL}/frontend/events/${data.event.id}`)
                        .then(responseEvent => {
                            setEventData(responseEvent.data.data);

                            let imgPath = responseEvent.data?.data?.photos[0]?.path;

                            if (imgPath) {
                                imgPath = imgPath.includes('/storage')
                                    ? `${process.env.REACT_APP_API_URL}/${imgPath}`
                                    : `${process.env.REACT_APP_API_URL}/storage/${imgPath}`
                            }

                            const bgColor = responseEvent.data?.data?.photos[0]?.pivot?.color || "#ffffff";

                            setEventBannerStyle({
                                ...eventBannerStyle,
                                background: `url('${imgPath}') center center / contain no-repeat, ${bgColor}`,
                            });
                        });
                })
                .catch((error) => {
                    console.error('Erro ao buscar pacotes:', error);
                });
        } catch (error) {
            console.error('Erro ao obter configurações:', error);
        }
    }, []);


    return <>
        <div className="bg-white overflow-hidden">
            {/* Banner de topo */}
            <section className="overflow-hidden full-screen h-[50vh] text-white pt-[23vh]" style={eventBannerStyle}>
                <Row className=''>
                    <Col xs={2}></Col>
                    <Col xs={4} className='text-white text-left my-2 tracking-wider text-2xl uppercase text-shadow-large font-extralight leading-[55px] font-serif text-shadow xl:!text-[30px] lg:!text-[30px] md:!text-[30px] md:!leading-[25px] sm:!text-[25px] sm:!mb-[30px] sm:!tracking-[-1px] sm:!leading-[43px] xs:!tracking-[-1px] xs:w-full xs:leading-none'>
                        {package_.name}
                    </Col>
                    <Col xs={2}></Col>
                </Row>
            </section>
            {/* Banner de topo */}

            {/* Informações do evento */}
            <section>
                <Row>
                    {eventData?.name
                        ? <>
                            <Col xs={12} className='text-[#0068D9] text-center my-10 uppercase tracking-wider'>
                                Evento
                            </Col>
                            <Col xs={12} className='text-black text-center my-2 uppercase tracking-wider text-4xl'>
                                {eventData?.name}
                            </Col>

                            <Col xs={1} lg={4}></Col>
                            <Col xs={10} lg={4} className='text-black text-center my-2 tracking-wide text-xl'>
                                <BrazilianFormatData date={eventData.date_start} showTime={false} />
                                -&nbsp;
                                <BrazilianFormatData date={eventData.date_end} showTime={false} />
                            </Col>
                            <Col xs={1} lg={4}></Col>
                        </>
                        : <Row className='mt-10'></Row>
                    }

                    
                    <Col xs={1} lg={2}></Col>
                    <Col xs={10} lg={8} className='text-black text-center my-2 tracking-wider text-xl font-bold'>
                        Pacote inclui
                    </Col>
                    <Col xs={1} lg={2}></Col>


                    <Col xs={1} lg={2}></Col>
                    <Col xs={10} lg={8} className='text-black text-center my-2 tracking-wider text-xl' dangerouslySetInnerHTML={{__html: package_?.highlights?.split("\\r").join("").split("\\n").join("<br>")}}>
                    </Col>
                   <Col xs={1} lg={2}></Col>

                </Row>
            </section>
            {/* Informações do evento */}

            {/* Opções de pacote */}
            <section>
                {/* <Debug package_={package_?.options} /> */}
                {package_?.options && package_.options.map((currentOption, index) => {
                    return <>
                        <Row>
                            <Col xs={1} lg={2}></Col>
                            <Col xs={10} lg={8} className='bg-[#0068D9] text-white text-center my-10 uppercase tracking-wider font-bold py-10 !rounded-[12px] text-2xl'>
                                {currentOption.condition}
                            </Col>
                            <Col xs={1} lg={2}></Col>

                            <Col xs={1} lg={2}></Col>
                            <Col xs={10} lg={8}>
                                {currentOption.hotels.map((packageHotel) => {
                                    const { hotel, condition } = packageHotel;
                                    const { photos } = hotel;

                                    return <>
                                        <Row className="my-14">
                                            <Col sm={1} xs={0}></Col>
                                            <Col sm={10} xs={12}>
                                                <Row>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        {photos?.length ? (
                                                            <img src={photos[0].base64encoded} style={{width: "100%"}} alt=""></img>
                                                        ) : null}
                                                    </Col>

                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <div>
                                                            {Array.from({ length: hotel.stars }).map(
                                                                (_, index) => {
                                                                return <span key={index}>&#11088;</span>;
                                                                }
                                                            )}
                                                        </div>
                                                        <div className='text-black font-bold tracking-wider text-2xl'>
                                                            {hotel.name}
                                                        </div>
                                                        <div className='text-black font-light tracking-wider text-xl'>
                                                            {hotel.address}
                                                        </div>
                                                        <div className='text-black font-bold tracking-wider text-lg'
                                                            dangerouslySetInnerHTML={{
                                                                __html: condition?.description,
                                                            }}
                                                        >
                                                        </div>
                                                        <div className="my-10">
                                                            <Button
                                                                aria-label="link"
                                                                className="btn-fill w-[100%] !mx-[0px] !border-[2px] !border-[#DC3545] hover:!border-[#ac1221] font-bold !tracking-wider font-serif uppercase lg:!mb-[15px] text-white !bg-[#DC3545] hover:!bg-[#ac1221] danger !px-[82px] !py-[17px] sm:!px-[42px] text-sm shadow-none !rounded-[50px]"
                                                                href={`#/frontend/reservation-form?package=${package_.id}&option=${currentOption.id}&hotel=${hotel.id}`}
                                                            >
                                                                Reservar
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={1} xs={0}></Col>
                                        </Row>
                                    </>
                                })}
                            </Col>
                            <Col xs={1} lg={2}></Col>

                            <Col xs={1} lg={2}></Col>
                            <Col xs={10} lg={8}>
                                <AirlineOption option={currentOption} />
                            </Col>
                            <Col xs={1} lg={2}></Col>

                        </Row>
                    </>
                })}
            </section>
            {/* Opções de pacote */}

            {/* Condições do pacote */}
            <section>
                <Row className='my-12 mx-2'>
                    <Col xs={1} lg={2}></Col>
                    <Col xs={10} lg={8} className='border rounded-[12px] py-4'>
                        <div className='text-black font-bold tracking-wider text-lg my-4'>
                            Condições gerais:
                        </div>
                        <div className='text-black tracking-wider text-lg'
                            dangerouslySetInnerHTML={{
                                __html: package_?.condition?.description,
                            }}
                        >
                        </div>
                    </Col>
                    <Col xs={1} lg={2}></Col>
                </Row>
            </section>
            {/* Condições do pacote */}
        </div>
    </>
};

export default PackageSelectHotels;