import React from 'react';
import PropTypes from 'prop-types';

const AirlineOption = ({ option }) => {
  const departureDatetimes = [];
  const returnDatetimes = [];

  option.datetimes.forEach((dateTime) => {
    dateTime.date = new Date(dateTime.date);

    if (dateTime.type === 'return') {
      returnDatetimes.push(dateTime);
      return;
    }
    departureDatetimes.push(dateTime);
  });

  return <>
    <div className='my-10 flex'>
      <div className='w-[50%] text-white py-12 px-10 text-right inline text-lg' style={{backgroundColor: "#56575B", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px", }}>
        Horários de voos <strong>{option.airline.name}</strong>
      </div>
      <div className='w-[50%] text-[#56575B] inline px-10 py-2 border border-[#56575B]' style={{backgroundColor: "#FFFFFF", borderTopRightRadius: "12px", borderBottomRightRadius: "12px", }}>
        <div>
          {departureDatetimes.length > 0 && departureDatetimes.map((datetime, index) => {
            return (<div key={index}>
              <strong>IDA:</strong>{' '}
              
              {datetime.date.toLocaleString('pt-br').split(', ').shift()}
              {' '}
              {datetime.date.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              })}

              {datetime.time_arrival && ` > ${datetime.time_arrival.substr(0, 5)}` }
              <br />
            </div>);
          })}

          {returnDatetimes.length > 0 && <br />}

          {returnDatetimes.length > 0 && returnDatetimes.map((datetime, index) => {
            return (<div key={index}>
              <strong>VOLTA:</strong>{' '}
              
              {datetime.date.toLocaleString('pt-br').split(', ').shift()}
              {' '}
              {datetime.date.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              })}

              {datetime.time_arrival && ` > ${datetime.time_arrival.substr(0, 5)}` }
              <br />
            </div>);
          })}

          </div>
      </div>
    </div>
  </>
};

AirlineOption.propTypes = {
  option: PropTypes.object.isRequired,
};

export default AirlineOption;
